
















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import decamelize from 'decamelize';

import {
  BAvatar,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BMedia,
  BMediaAside, BMediaBody,
  BRow
} from "bootstrap-vue";

@Component({
  components: {}
})
export default class ReportDownload extends Vue {
  downloading = true;
  async created() {
    await this.$api.reporting.reports.Download(this.$route.params.identifier);
    this.downloading = false;
  }
}
